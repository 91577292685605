import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Editor from "./scenes/editor";
import EditorAdd from "./scenes/editorAdd";
import Levels from "./scenes/levels";
import Packs from "./scenes/packs";
import Musics from "./scenes/musics";
import Badges from "./scenes/badges";
import AddBadge from "./scenes/add_badge";
// lessons
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Lessons from "./scenes/lessons";
import Quiz from "./scenes/quiz";
// login
import Edit from "./scenes/edit";
import Login from "./scenes/login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Toaster } from 'react-hot-toast';
import Map from "./scenes/Map";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authToken, setAuthToken] = useState(""); // Replace with your authentication token logic

  useEffect(() => {
    // Retrieve the authentication token from async storage or session
    const storedAuthToken = sessionStorage.getItem("authToken");
    setAuthToken(storedAuthToken);
  }, []);
  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <ToastContainer />

          {authToken ? (
            <>

              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar 
                setAuthToken={setAuthToken}
                setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/badges" element={<Badges />} />
                  <Route path="/addBadge/:user_id" element={<AddBadge />} />
                  <Route path="/edit/:type/:table/:id" element={<Edit />} />
                  <Route path="/packs/:activity" element={<Packs />} />
                  <Route path="/levels/:activity" element={<Levels />} />
                  <Route path="/lessons/:activity/:level" element={<Lessons />} />
                  <Route path="/musics/:activity/:pack" element={<Musics />} />
                  <Route path="/quiz/:lesson_id" element={<Quiz />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/editor/:activity/:level/:lesson" element={<Editor />} />
                  <Route path="/addLesson/:activity/:level" element={<EditorAdd />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/geography" element={<Map />} />
                </Routes>
              </main>
            </>
          ) : (
            <Login setAuthToken={setAuthToken} />
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
