import { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      
    </MenuItem>
    </Link>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");


  // get from session : username, roles, avatar
  const username = sessionStorage.getItem("username");
  const roles_array = sessionStorage.getItem("role");
  const avatar = sessionStorage.getItem("avatar");

  // JSON.parse roles and join the array by - 
  const roles = JSON.parse(roles_array).join(" - ");



  const roleCheck = (roles) => {
   try {
    // check if json parse of session storage "role" contains one of the roles
    const userRoles = JSON.parse(sessionStorage.getItem("role"));
    return roles.some((role) => userRoles.includes(role));
   }
    catch (e) {
      return false;
    }
  }
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img 
                // logo.svg
                alt="logo"
                width="100px"

                src="/assets/logo.svg"
                />
              
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={"https://api.drp-arts.com/public/avatars/"+avatar}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {username}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {roles}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Lessons
            </Typography>
            {roleCheck(["piano"]) && (
            <Item
              title="Piano"
              to="/levels/piano"
              icon={<img src="/assets/activities/piano.png" alt="piano" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />

            )}
          
            
            {roleCheck(["guitar"]) && (
            <Item
              title="Guitar"
              to="/levels/guitar"

              icon={<img src="/assets/activities/guitar.png" alt="guitar" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
            {roleCheck(["violin", "piano"]) && (
            <Item
              title="Violin"
              to="/levels/violin"
              icon={<img src="/assets/activities/violin.png" alt="violin" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />

            )}

            {roleCheck(["drawing"]) && (
            <Item
              title="Drawing"
              to="/levels/drawing"
              icon={<img src="/assets/activities/drawing.png" alt="drawing" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />
            )}

            {roleCheck(["solfeggio"]) && (
            <Item
              title="Solfeggio"
              to="/levels/solfeggio"
              icon={<img src="/assets/activities/music.png" alt="solfeggio" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />

            )}


<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Musics
            </Typography>
            {roleCheck(["piano"]) && (
        <Item
        title="Piano"
        icon={<img src="/assets/activities/piano.png" alt="piano" width="20px" height="20px" />}
        to = "/musics/piano/all"
        selected={selected}
        setSelected={setSelected}
      />
            )}
          
            
            {roleCheck(["guitar"]) && (
            <Item
              title="Guitar"
              to="/musics/guitar/all"
              icon={<img src="/assets/activities/guitar.png" alt="guitar" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />
            )}

        <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Packs
            </Typography>
            {roleCheck(["piano"]) && (
        <Item
        title="Piano"
        to="/packs/piano"
        icon={<img src="/assets/activities/piano.png" alt="piano" width="20px" height="20px" />}
        selected={selected}
        setSelected={setSelected}
      />
            )}
          
            
            {roleCheck(["guitar"]) && (
            <Item
              title="Guitar"
              to="/packs/guitar"
              icon={<img src="/assets/activities/guitar.png" alt="guitar" width="20px" height="20px" />}
              selected={selected}
              setSelected={setSelected}
            />
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Users"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invoices"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              
              DRP
            </Typography>
            <Item
              title="Badges"
              to="/badges"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
