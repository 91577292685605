import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { wilayas } from "../../data/mockData";



import { useState, useEffect } from "react";
const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [mockDataContacts, setMockDataContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState("");


  const activities_icons = [
    { name: "piano",  icon: 'piano.png' },
    { name: "guitar", icon: 'guitar.png' },
    { name: "violin", icon: 'violin.png' },
    { name: "drawing", icon: 'drawing.png' },
    { name: "solfeggio", icon: 'music.png' },
  ];

  // use effect search page
  useEffect(() => {
    fetchContacts();
  }, [page, search]);


  const fetchContacts = async () => {
    setLoading(true);

    try {
      const authToken = sessionStorage.getItem("authToken");
      // post, with body : authtoken, page, search
       const response = await fetch('https://api.drp-arts.com/admin/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authToken, page, search }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contacts');
      }



      const data = await response.json();
      console.log(data)
      setMockDataContacts(data.data);
      setRowCount(data.total);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching contacts:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchContacts();
  }, []);
  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "username",
      headerName: "Userame",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // one for name and fname
    {
        field: "name",
        headerName: "Name",
        flex: 1,

    },
    {
        field : "fname",
        headerName: "First Name",
        flex: 1,
    },
    {
      field: "birthday",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
      renderCell: ({ row: { birthday } }) => {
        // calculate age
        const today = new Date();
        const birthDate = new Date(birthday);
        const age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          return age - 1;
        }
        return age;
      }

    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "wilaya",
      headerName: "Wilaya",
      flex: 1,
      renderCell: ({ row: { wilaya } }) => { 
        const wilayaName = wilayas.find(w => w.id === parseInt(wilaya))?.name;
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            color={colors.grey[100]}
          >
            {wilayaName}
          </Box>
        );
      }
    },
    {
      field: "activities",
      headerName: "Activities",
      flex: 1,
      renderCell: ({ row: { activities } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            color={colors.grey[100]}
          >
            {activities?.map((activity) => {
              if (activity.type !== "f") {
                return (
                  <Box key={activity.activity} m="0 5px">
                    <img
                      src={`./assets/activities/${activity.activity}.png`}
                      alt={activity.activity}
                      style={{
                        backgroundColor: activity.type === "s" ? colors.greenAccent[600] : "transparent",
                        padding: activity.type === "s" ? "5px" : "0",
                      }}
                      width="20px"
                      height="20px"
                    />
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        );
      },



    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: () => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            color={colors.grey[100]}
            // when click go to route /teams with parameter : id = 'bib'
            component={Link}
// to : teams and state : { id : 'bib' }
            to={{
              pathname: "/team",
            }}
            state={{ id: "bib" }}
            cursor="pointer"
          >
            Edit
          </Box>
        );
      },
    },
    {
      field: "add_badge",
      headerName: "Add Badge",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            color={colors.grey[100]}
            // when click go to route /teams with parameter : id = 'bib'
            component={Link}
            to = { `/addBadge/${id}` }
            cursor="pointer"
          >
            Add Badge
          </Box>
        );
      }

    },


  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
      <Header
        title="CONTACTS"
        subtitle="List of Contacts for Future Reference"
      />
       <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase 
        // its value is 'search'
      value={search}
      // when change set the value of search to the value of the input
      onChange={(e) => setSearch(e.target.value)}


        sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataContacts}
          columns={columns}
         slots={{ toolbar: CustomToolbar }}
          pagination
          autoPageSize
          rowCount={rowCount}
          loading={loading}
          paginationMode="server"
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page + 1);
          }
          }


         // onPageChange={(newPage) => setPage(newPage)}

          

          
        />
      </Box>
    </Box>
  );
};

export default Contacts;
