import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AddCircle, Delete, CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';


const languages = ['English', 'French', 'Arabic']
const QuizEditor = () => {
  const { lesson_id } = useParams();
  const navigate = useNavigate();

  // console.log what is passed in use params
  console.log(useParams())
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('English');
    const [questions, setQuestions] = useState([]);
    const [editedQuestions, setEditedQuestions] = useState({}); // Questions actuellement en cours d'édition
    const [newQuestion, setNewQuestion] = useState({
        question: '',
        replies: {},
        rightReplies: [],
        type: 'checkbox'
    });
    const [deletedQuestions, setDeletedQuestions] = useState([]); // Questions supprimées

    useEffect(() => {
        // Fonction pour récupérer les questions depuis l'API
        const fetchQuestions = async () => {
          const authToken = sessionStorage.getItem("authToken");
            try {
              const response = await fetch('https://api.drp-arts.com/admin/quiz', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ authToken, lesson_id })
              });
              if (response.ok) {
                let data = await response.json();
                // Sélectionner automatiquement les réponses correctes
                data = data.quizData
                const updatedQuestions = data.map(question => {
                  if (question.type !== 'input') {
                    return {
                      ...question,
                      rightReplies: question.right_replies.map(reply => reply.toString()) // Convertir en chaîne de caractères
                    };
                  }
                  return question;
                });
                setQuestions(updatedQuestions);
              } else {
                console.error('Failed to fetch questions');
              }
            } catch (error) {
              console.error('Error fetching questions:', error);
            }
          
          };
        

        fetchQuestions(); // Appel de la fonction pour récupérer les questions
    }, []);

 

    const handleAddQuestion = () => {
    

      const updatedQuestions = [...questions, newQuestion];
      setQuestions(updatedQuestions);
      setNewQuestion({
          question: '',
          question_fr: '',
          question_ar: '',
          replies: {},
          replies_fr: {1: ''}, // Initialisation de replies_fr avec une réponse par défaut
          replies_ar: {1: ''}, // Initialisation de replies_ar avec une réponse par défaut
          rightReplies: [],
          type: 'checkbox'
      });
  };
  

    const handleDeleteQuestion = (questionId) => {
        const updatedQuestions = questions.filter(question => question.id !== questionId);
        setQuestions(updatedQuestions);
        // Ajouter l'ID de la question supprimée à la liste des questions supprimées
        setDeletedQuestions([...deletedQuestions, questionId]);
        // Supprimer la question éditée si elle est supprimée de la liste principale
        if (editedQuestions[questionId]) {
            const { [questionId]: removedQuestion, ...rest } = editedQuestions;
            setEditedQuestions(rest);
        }
    };

    const handleDeleteReply = (replyKey, questionId) => {
      const updatedReplies = { ...editedQuestions[questionId].replies };
      const updatedRepliesFr = { ...editedQuestions[questionId].replies_fr };
      const updatedRepliesAr = { ...editedQuestions[questionId].replies_ar };
      delete updatedReplies[replyKey];
      delete updatedRepliesFr[replyKey];
      delete updatedRepliesAr[replyKey];
  
      // Réorganiser les clés des réponses après la suppression
      const reorganizedReplies = {};
      const reorganizedRepliesFr = {};
      const reorganizedRepliesAr = {};
      Object.keys(updatedReplies).forEach((key, index) => {
          reorganizedReplies[index + 1] = updatedReplies[key];
          reorganizedRepliesFr[index + 1] = updatedRepliesFr[key];
          reorganizedRepliesAr[index + 1] = updatedRepliesAr[key];
      });
  
      // Vérifier si l'index de la réponse supprimée est dans rightReplies
      const updatedRightReplies = editedQuestions[questionId].rightReplies.filter(item => item !== replyKey.toString());
  
      setEditedQuestions({ 
          ...editedQuestions, 
          [questionId]: { 
              ...editedQuestions[questionId], 
              replies: reorganizedReplies, 
              replies_fr: reorganizedRepliesFr, 
              replies_ar: reorganizedRepliesAr, 
              rightReplies: updatedRightReplies 
          } 
      });
  };
  

    const handleSaveQuestions = async () => {
        // Sauvegarde de toutes les questions à la fois
        // Ici, vous pouvez implémenter la logique d'envoi des questions à votre API
        console.log('Saving questions:', questions);
        // filteredEditedQuestions = editedQuestions sans les values avec key undefined
        const filteredEditedQuestions = Object.entries(editedQuestions).filter(([key]) => questions.find(question => question.id === key)).reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
        console.log('Edited questions:', filteredEditedQuestions);
        // prends tous les values avec key undefined et mets les dans un array addedQuestions, genere un key de 1..... etc
        const addedQuestions = Object.entries(editedQuestions).filter(([key]) => !questions.find(question => question.id === key)).map(([key, value]) => ({ ...value, id: key }));
        console.log('Added questions:', addedQuestions);
        console.log('Deleted questions:', deletedQuestions);

        // envoie les questions à l'api 
        const authToken = sessionStorage.getItem("authToken");
        setLoading(true);
        try {
        const response = await fetch('https://api.drp-arts.com/admin/updateQuiz', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ authToken, lesson_id, addedQuestions, editedQuestions: filteredEditedQuestions, deletedQuestions })
        })

        const data = await response.json();
        console.log(data);


        if (response.ok) {
            toast.success('Questions saved successfully');
            navigate(-1);
          console.log('Questions saved successfully');
        }else {
            toast.error('Error saving questions');
          console.error('Failed to save questions');
        }

        } catch (error) {
            toast.error('Error saving questions');
          console.error('Error saving questions:', error);
        } finally {
            setLoading(false);
            }


        

    };

    const handleSetAsCorrectAnswer = (replyKey, questionId) => {
        const editedQuestionCopy = { ...editedQuestions[questionId] };
        if (editedQuestionCopy.type === 'radio') {
            // Pour le type 'radio', définir la réponse correcte directement
            editedQuestionCopy.rightReplies = [replyKey.toString()]; // Convertir en chaîne de caractères
        } else {
            // Pour le type 'checkbox', basculer l'état de la réponse correcte
            const updatedRightReplies = editedQuestionCopy.rightReplies.includes(replyKey.toString()) ?
                editedQuestionCopy.rightReplies.filter(item => item !== replyKey.toString()) :
                [...editedQuestionCopy.rightReplies, replyKey.toString()];
            editedQuestionCopy.rightReplies = updatedRightReplies;
        }
        setEditedQuestions({ ...editedQuestions, [questionId]: editedQuestionCopy });
    };

    const handleTypeChange = (e, questionId) => {
        const newType = e.target.value;
        // Réinitialiser les réponses lorsque le type de question est modifié
        const newReplies = newType === 'input' ? {} : { 1: '' }; // Réponse par défaut
        setEditedQuestions({ ...editedQuestions, [questionId]: { ...editedQuestions[questionId], replies: newReplies, type: newType } });
    };

    return (
        <div style={{ margin: '20px' }}>
          <select 
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          name="language" id="language">
            {languages.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
            {/* Affichage des questions */}
            {questions.map(question => (
                <div key={question.id} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
                    {/* Affichage de la question et des détails */}
                    <Typography 
                    // display if  language is english
                     
                    style={{ display: language === 'English' ? 'block' : 'none' }} 
                    variant="h5">{question.question}
                    </Typography>
                    <Typography
                    style={{ display: language === 'French' ? 'block' : 'none' }}
                    variant="h5">{question.question_fr}
                    </Typography>
                    <Typography
                    style={{ display: language === 'Arabic' ? 'block' : 'none' }}
                    variant="h5">{question.question_ar}
                    </Typography>
                    {editedQuestions[question.id] ? (
                        // Formulaire pour éditer la question
                        <div>
                            <TextField fullWidth label="Question (EN)"
                            style={{ marginBottom: '10px', display: language === 'English' ? 'block' : 'none' }}
                            value={editedQuestions[question.id].question} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], question: e.target.value } })}  />
                            <TextField fullWidth label="Question (FR)"
                            style={{ marginBottom: '10px', display: language === 'French' ? 'block' : 'none' }}
                            value={editedQuestions[question.id].question_fr} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], question_fr: e.target.value } })}  />
                            <TextField fullWidth label="Question (AR)"
                            style={{ marginBottom: '10px', display: language === 'Arabic' ? 'block' : 'none' }}
                            value={editedQuestions[question.id].question_ar} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], question_ar: e.target.value } })}  />
                            
                            {/* Affichage des réponses */}
                            {Object.entries(editedQuestions[question.id].replies).map(([key, value]) => (
                                <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    {editedQuestions[question.id].type === 'input' ? (
                                      <>
                                                                              <TextField fullWidth label={`Reply ${key}`} value={value} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], replies: { ...editedQuestions[question.id].replies, [key]: e.target.value } } })} style={{ marginRight: '10px', display: language === 'English' ? 'block' : 'none' }} />
                                        <TextField fullWidth label={`Reply ${key}`} value={editedQuestions[question.id].replies_fr[key]} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], replies: { ...editedQuestions[question.id].replies_fr, [key]: e.target.value } } })} style={{ marginRight: '10px', display: language === 'French' ? 'block' : 'none' }} />
                                        <TextField fullWidth label={`Reply ${key}`} value={editedQuestions[question.id].replies_ar[key]} onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: { ...editedQuestions[question.id], replies: { ...editedQuestions[question.id].replies_ar, [key]: e.target.value } } })} style={{ marginRight: '10px', display: language === 'Arabic' ? 'block' : 'none' }} />

                                      </>
                                    ) : (
                                        <>
                                           <TextField 
    fullWidth 
    label={`Reply ${key}`} 
    value={editedQuestions[question.id].replies[key]} 
    onChange={(e) => setEditedQuestions({ 
        ...editedQuestions, 
        [question.id]: { 
            ...editedQuestions[question.id], 
            replies: { 
                ...editedQuestions[question.id].replies, 
                [key]: e.target.value 
            } 
        } 
    })} 
    style={{ marginRight: '10px', display: language === 'English' ? 'block' : 'none' }} 
/>

<TextField 
    fullWidth 
    label={`Reply ${key}`} 
    value={editedQuestions[question.id].replies_fr[key]} 
    onChange={(e) => setEditedQuestions({ 
        ...editedQuestions, 
        [question.id]: { 
            ...editedQuestions[question.id], 
            replies_fr: { 
                ...editedQuestions[question.id].replies_fr, 
                [key]: e.target.value 
            } 
        } 
    })} 
    style={{ marginRight: '10px', display: language === 'French' ? 'block' : 'none' }} 
/>

<TextField 
    fullWidth 
    label={`Reply ${key}`} 
    value={editedQuestions[question.id].replies_ar[key]} 
    onChange={(e) => setEditedQuestions({ 
        ...editedQuestions, 
        [question.id]: { 
            ...editedQuestions[question.id], 
            replies_ar: { 
                ...editedQuestions[question.id].replies_ar, 
                [key]: e.target.value 
            } 
        } 
    })} 
    style={{ marginRight: '10px', display: language === 'Arabic' ? 'block' : 'none' }} 
/>

                                            {/* Bouton pour définir comme réponse correcte */}
                                            <Button 
    variant="contained" 
    color={editedQuestions[question.id].rightReplies && editedQuestions[question.id].rightReplies.includes(key.toString()) ? 'success' : 'error'} 
    onClick={() => handleSetAsCorrectAnswer(key, question.id)}
>
    {editedQuestions[question.id].type === 'checkbox' ? 
        (editedQuestions[question.id].rightReplies && editedQuestions[question.id].rightReplies.includes(key.toString()) ? 
            <CheckCircleOutline /> : 
            <RadioButtonUnchecked />
        ) : 
        (editedQuestions[question.id].rightReplies && editedQuestions[question.id].rightReplies.includes(key.toString()) ? 
            <CheckCircleOutline /> : 
            <RadioButtonUnchecked />
        )
    }
</Button>
                                        </>
                                    )}
                                    <Button variant="contained" onClick={() => handleDeleteReply(key, question.id)}><Delete /></Button>
                                </div>
                            ))}
                            {/* Ajout de nouvelles réponses */}
                            {editedQuestions[question.id].type !== 'input' && (
                         <Button 
                         variant="contained" 
                         onClick={() => {
                          const nextReplyKey = Object.keys(editedQuestions[question.id].replies).length + 1;
                          const nextReplyKey_fr = editedQuestions[question.id].replies_fr ? Object.keys(editedQuestions[question.id].replies_fr).length + 1 : 1;
                          const nextReplyKey_ar = editedQuestions[question.id].replies_ar ? Object.keys(editedQuestions[question.id].replies_ar).length + 1 : 1;
                     
                             setEditedQuestions({ 
                                 ...editedQuestions, 
                                 [question.id]: { 
                                     ...editedQuestions[question.id], 
                                     replies: { 
                                         ...editedQuestions[question.id].replies, 
                                         [nextReplyKey]: '' 
                                     }, 
                                     replies_fr: { 
                                         ...editedQuestions[question.id].replies_fr, 
                                         [nextReplyKey_fr]: '' 
                                     }, 
                                     replies_ar: { 
                                         ...editedQuestions[question.id].replies_ar, 
                                         [nextReplyKey_ar]: '' 
                                     } 
                                 } 
                             });
                         }}
                     >
                         <AddCircle /> Add Reply
                     </Button>
                     
                            )}
                            {/* Choix du type de question */}
                            <FormControl fullWidth style={{ marginTop: '10px' }}>
                                <InputLabel>Type</InputLabel>
                                <Select value={editedQuestions[question.id].type} onChange={(e) => handleTypeChange(e, question.id)}>
                                    <MenuItem value="checkbox">Checkbox</MenuItem>
                                    <MenuItem value="radio">Radio</MenuItem>
                                    <MenuItem value="input">Input</MenuItem>
                                </Select>
                            </FormControl>
                            {/* Bouton pour supprimer la question */}
                            <Button variant="contained" color="error" onClick={() => handleDeleteQuestion(question.id)}>Delete</Button>
                        </div>
                    ) : (
                        // Bouton pour éditer la question
                        <Button variant="contained" onClick={() => setEditedQuestions({ ...editedQuestions, [question.id]: { ...question } })}>Edit</Button>
                    )}
                </div>
            ))}

            {/* Formulaire pour ajouter une nouvelle question */}
            <div style={{ marginTop: '20px' }}>
                <Typography variant="h4">Add New Question</Typography>
                <TextField fullWidth label="Question (EN)" value={newQuestion.question} onChange={(e) => setNewQuestion({ ...newQuestion, question: e.target.value })} style={{ marginBottom: '10px' }} />
                <TextField fullWidth label="Question (FR)" value={newQuestion.question_fr} onChange={(e) => setNewQuestion({ ...newQuestion, question_fr: e.target.value })} style={{ marginBottom: '10px' }} />
                <TextField fullWidth label="Question (AR)" value={newQuestion.question_ar} onChange={(e) => setNewQuestion({ ...newQuestion, question_ar: e.target.value })} style={{ marginBottom: '10px' }} />
                <Button variant="contained" onClick={handleAddQuestion}>Add Question</Button>
            </div>

            {/* Bouton pour sauvegarder toutes les questions */}
            <div style={{ marginTop: '20px' }}>
                <Button variant="contained" onClick={handleSaveQuestions}>Save All Questions</Button>
            </div>
        </div>
    );
};

export default QuizEditor;
