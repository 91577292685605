import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Button, InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { wilayas } from "../../data/mockData";



import { useState, useEffect } from "react";
const Badges = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [mockDataContacts, setMockDataContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState("");

  // use effect search page
  useEffect(() => {
    fetchContacts();
  }, [page, search]);


  const fetchContacts = async () => {
    setLoading(true);

    try {
      const authToken = sessionStorage.getItem("authToken");
      // post, with body : authtoken, page, search
       const response = await fetch('https://api.drp-arts.com/admin/badges', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authToken, page, search }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contacts');
      }



      const data = await response.json();
      console.log(data)
      setMockDataContacts(data.data);
      setRowCount(data.total);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching contacts:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchContacts();
  }, []);
  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },

    // one for name and fname
    {
        field: "name",
        headerName: "Name",
        flex: 1,

    },
    {
        field : "description",
        headerName: "Description",
        flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: ( { row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            color={colors.grey[100]}
            // when click go to route /teams with parameter : id = 'bib'
            component={Link}
            to={ `/edit/edit/badges/${id}` }

            cursor="pointer"
          >
            Edit
          </Box>
        );
      },
    },


  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Box display="flex" alignItems="center" justifyContent="center">
              <Button
              to={`/edit/add/badges/add`}
              component={Link}
              color="secondary" variant="contained">
                Create New Badge
              </Button>
            </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
      <Header
        title="BADGES"
        subtitle="List of Badges for Future Reference"
      />
       <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase 
        // its value is 'search'
      value={search}
      // when change set the value of search to the value of the input
      onChange={(e) => setSearch(e.target.value)}


        sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataContacts}
          columns={columns}
         slots={{ toolbar: CustomToolbar }}
          pagination
          autoPageSize
          rowCount={rowCount}
          loading={loading}
          paginationMode="server"
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page + 1);
          }
          }


         // onPageChange={(newPage) => setPage(newPage)}

          

          
        />
      </Box>
    </Box>
  );
};

export default Badges;
