import React from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FileIcon, defaultStyles } from "react-file-icon";

const FileInput = ({ field, table, config, values, initialValues, handleChange, handleBlur, errors, touched }) => {
    const extensions = config.tables[table].fields[field].extensions || [];
    const preview = config.tables[table].fields[field].preview || false;
    const prefix = config.tables[table].fields[field].prefix || "";
    const required = config.tables[table].fields[field].required || false;
    const originalValue = values[field] || "";
    const extractFileName = (filePath) => {
        // stringify
        if (!filePath) return "";
        return filePath.replace("C:\\fakepath\\", "");
    };
    const [filePreview, setFilePreview] = React.useState(null); // State to store file preview

    const handleFileChange = (event, fiele) => {
        const file = event.target.files[0];
        if (file) {
            // check if the file type is allowed
            if (extensions.length > 0) {
                console.log("extensions : ", extensions)
                const allowed = extensions.some((ext) => file.name.endsWith(ext));
                if (!allowed) {
                    alert("File type not allowed");
                    setFilePreview(null);
                    return;
                }
            }
    
            // check if the file size is allowed
            const { size } = file;
            const maxSize = config.tables[table].fields[field].maxSize || 0;
            if (maxSize > 0 && size > maxSize) {
                alert("File size too large");
                setFilePreview(null);
                return;
            }
    
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
                // Stockez le résultat de la lecture (data URI) dans values[field]
                handleChange({
                    target: {
                        name: field,
                        value: file,
                    }
                });
            };
            
            // Utilisez readAsDataURL pour obtenir un data URI
            reader.readAsDataURL(file);
        } else {
            setFilePreview(null);
            // Si aucun fichier n'est sélectionné, réinitialisez la valeur du champ à vide
            handleChange({
                target: {
                    name: field,
                    value: "",
                }
            });
        }
    };
    

    const previewFile = (preview, file) => {
        console.log(file)
        // get the last part after . after stringify file
      
        if (preview) {
            // return img

            return (
                <img src={file} alt="preview" style={{ maxWidth: "100px" }} />
            )
        }else {
            let ext;
            try {
                ext = file.split('.').pop();

            }catch (e) {
                console.log(e)
                ext = "txt"
            }   
            return (
                <div                 className="file-icon" >

<FileIcon 

extension={ext} {...defaultStyles[ext]} />
                </div>


            )
        }

    }

    const handleResetFile = () => {
        console.log(initialValues)
        handleChange({
            target: {
                name: field,
                value: initialValues[field] || ""
            }
        });
        setFilePreview(null);

    };


    return (
        <div
            style={{
                position: "relative",
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px"
            }}
            key={field}>
            <p
                style={{
                    position: "absolute",
                    top: "-30px",
                    fontSize: "10px",
                    color: "gray",
                    padding: "0 5px"
                }}>
                {field} bbb
                {required && <span>*</span>}
                {extensions.length > 0 && (
                    <span style={{ marginLeft: "10px" }}>Extensions: {extensions.join(", ")}</span>
                )}
            </p>
            <TextField
                name={field}
                type="file"
                id={"contained-button-file"+field}
                className="file-input"


                required={config.tables[table].fields[field].required}
                fullWidth
                onChange={(event) => handleFileChange(event, field)}
                                onBlur={handleBlur}
                // hide text
                

                
                error={touched[field] && Boolean(errors[field])}
            />
             <label htmlFor={"contained-button-file"+field}>
   <Button variant="contained" component="span"> 
     Upload 
   </Button> 
 </label> 
            <div style={{}}>
                {filePreview && (
                    <img
                        src={filePreview ? filePreview : prefix + values[field]}
                        alt="preview"
                        style={{ maxWidth: "100px" }}
                    />
                )}

                {!filePreview && prefix && (
            <img src={extractFileName(prefix + values[field])} alt="preview" style={{ maxWidth: "100px" }} />
                )
}


{!filePreview && !prefix && (
    previewFile(preview, values[field])
)}

            </div>
            {filePreview && (
                <IconButton
                    aria-label="reset file"
                    onClick={handleResetFile}
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                    <ClearIcon />
                </IconButton>
            )}
        </div>
    );
};

const Input = ({ table, config, values, initialValues, handleChange, handleBlur, errors, touched }) => {
    const generateForm = () => {
        const fields = Object.keys(config.tables[table].fields);
        return fields.map((field) => {
            const fieldType = config.tables[table].fields[field].field_type || "textfield";

            if (fieldType === "select") {
                const options = config.tables[table].fields[field].options || {};
                return (
                    <FormControl key={field} fullWidth>
                        <InputLabel>{field}</InputLabel>
                        <Select
                            name={field}
                            value={values[field] || ""}
                            required={config.tables[table].fields[field].required}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched[field] && Boolean(errors[field])}
                            helperText={touched[field] && errors[field]}
                        >
                            {Object.entries(options).map(([value, name]) => (
                                <MenuItem key={value} value={value}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }

            
            if (fieldType === "file") {
                
                return (
                    <>
                    <FileInput
                        key={field}
                        field={field}
                        table={table}
                        config={config}
                        values={values}
                        initialValues={initialValues}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                    />
                    </>
                );
            }

            return (
                <TextField
                    key={field}
                    name={field}
                    label={field}
                    value={values[field] || ""}
                    type={config.tables[table].fields[field].type}
                    required={config.tables[table].fields[field].required}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[field] && Boolean(errors[field])}
                    helperText={touched[field] && errors[field]}
                />
            );
        });
    };

    return (
        <>
            {generateForm()}
        </>
    );
};

export default Input;
