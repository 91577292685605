import React, { useState, useEffect } from 'react';
import { Box, Button } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useParams, useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import Input from "./components/Input";
import { toast } from 'react-toastify';

const Edit = () => {
    const { type, table, id } = useParams();
    const [values, setValues] = useState({});
    const [originalValues, setOriginalValues] = useState({});
    const [isNonMobile, setIsNonMobile] = useState(false);
    const [packOptions, setPackOptions] = useState({});
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const getPacks = async () => {
            try {
                const authToken = sessionStorage.getItem("authToken");
                const response = await fetch("https://api.drp-arts.com/admin/getPacksOptions", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ authToken }),
                });
                const data = await response.json();
                if (data.success) {
                    // add to data.options, "" : "No pack"
                    data.options["[NULL]"] = "No pack";
                    setPackOptions(data.options); // Set pack options in state
                } else {
                    console.error("Failed to fetch pack options:", data.message);
                }
            } catch (error) {
                console.error("Error fetching pack options:", error);
            }
        };

        getPacks(); // Call the function to fetch pack options when component mounts
    }, []); // Empty dependency array to run the effect only once when component mounts

    const config = {
        endpoints: {
            get_data: "https://api.drp-arts.com/admin/getData",
            add: "https://api.drp-arts.com/admin/addData",
            edit: "https://api.drp-arts.com/admin/editData",
            delete: "https://api.drp-arts.com/admin/deleteData"
        },
        tables: {
            levels: {
                fields: {
                    name: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    description: {
                        type: "string",
                        required: true
                    },
                    name_fr: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    description_fr: {
                        type: "string",
                        required: true
                    },
                    name_ar: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                    },
                    description_ar: {
                        type: "string",
                        required: true
                    },
                    activity: {
                        field_type: "select",
                        options: {
                            "piano": "Piano",
                            "guitar": "Guitar",
                            "violin": "Violin",
                            "drawing": "Drawing",
                            "solfeggio": "Solfeggio",
                        },
                        required: true
                    },
                    premium: {
                        field_type: "select",
                        options: {
                            "1": "Yes",
                            "0": "No",
                        },
                    },

                },
            },
            quiz: {
                fields: {
                    question: {
                        type: "string",
                        required: true,
                        maxLength: 255,
                        minLength: 3,
                    },
                    replies: {
                        type: "custom",
                        renderJSON : (value) => {
                            // for every value crate input
                            return value.map((reply, index) => (
                                <input key={index} type="text" value={reply} />
                            ));
                        },
                    },
                },
            },
            packs: {
                fields: {
                    name: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    description: {
                        type: "string",
                        required: true
                    },
                    activity: {
                        field_type: "select",
                        options: {
                            "piano": "Piano",
                            "guitar": "Guitar",
                        },
                        required: true
                    },
                    price: {
                        type: "number",
                        required: true
                    },
                    picture: {
                        field_type: "file",
                        extensions: ["jpg", "jpeg", "png"],
                        size: "2MB",
                        preview: true,
                        prefix: "https://api.drp-arts.com/public/packs/"
                    },
                },
            },
            musics : {
                fields: {
                    name: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    pack_id : {
                        field_type: "select",
                        options: packOptions,
                        required: true
                    },
                    activity: {
                        field_type: "select",
                        options: {
                            "piano": "Piano",
                            "guitar": "Guitar",
                        },
                        required: true
                    },
                    artist: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    style : {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    picture : {
                        field_type: "file",
                        extensions: ["jpg", "jpeg", "png"],
                        size: "2MB",
                        preview: true,
                        prefix: "https://api.drp-arts.com/public/musics/"
                    

                    },
                    file : {
                        field_type: "file",
                        extensions: ["mid", "gp3", "gp4", "gp5", "gpx", "gp", "pdf"],
                        size: "10MB",
                        preview: false
                    },
                    premium: {
                        field_type: "select",
                        options: {
                            "1": "Yes",
                            "0": "No",
                        },
                    },
                
                },
            },
            badges: {
                fields: {
                    name: {
                        type: "string",
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                        pattern: /^[a-zA-Z\s]*$/,
                    },
                    description: {
                        type: "string",
                        required: true
                    },
                    picture: {
                        field_type: "file",
                        extensions: ["jpg", "jpeg", "png"],
                        size: "2MB",
                        preview: true,
                        prefix: "https://api.drp-arts.com/public/badges/"
                    },
                },
            },
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = sessionStorage.getItem("authToken");
                const response = await fetch(config.endpoints.get_data, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ table, id, authToken }),
                });
                const data = await response.json();
                if (data.success) {
                    const fields = Object.keys(config.tables[table].fields);
                    const filteredData = {};
                    fields.forEach((field) => {
                        filteredData[field] = data.data[field];
                    });
                    setValues(filteredData);
                    setOriginalValues(filteredData);
                    console.log("Data fetched:", filteredData);
                } else {
                    console.error("Failed to fetch data:", data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (type === "edit") {
            fetchData();
        }
    }, [type, table, id]);

    const validationSchema = () => {
        const fields = Object.keys(config.tables[table].fields);
        const schema = {};
        fields.forEach((field) => {
            schema[field] = yup.string().required("Required");
        });
        return yup.object().shape(schema);
    };

const handleFormSubmit = (values) => {
    const authToken = sessionStorage.getItem("authToken");

    // Créez un nouveau FormData
    const formData = new FormData();

    formData.append("authToken", authToken);

    // add id to the form data
    formData.append("id", id);

    formData.append("table", table);
    let data = {};


    Object.keys(values).forEach((key) => {
     // check if key has no field_type = file
        if (config.tables[table].fields[key].field_type !== "file") {
            data[key] = values[key];
            console.log("Data:", data);
        }  else {
            if (values[key] !== originalValues[key]) {
                formData.append(key, values[key]);
                console.log("key:", values[key]);
            }
        }
    });

    formData.append("data", JSON.stringify(data));
    console.log("Form data:", data);

    // if table = levels 
    if (table === "levels" && type === "add") {
        // loading true
        setLoading(true);
        fetch("https://api.drp-arts.com/admin/addLevel", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ data, authToken }),
            })
        .then(response => response.json())
        .then(data => {
            console.log("Data:", data);
            if (data.success) {
                setLoading(false);
                toast.success('Level added successfully');
                navigate(-1);
            }else {
                setLoading(false);
                toast.error('Failed to add level');
            }
        })

        .catch(error => {
            setLoading(false);
            toast.error('Failed to add level');
            console.error("Error:", error);
        }

        );
        return;
    }


    // Déterminez l'endpoint en fonction du type (ajout ou édition)
    const endpoint = type === "add" ? config.endpoints.add : config.endpoints.edit;

    setLoading(true);
    // Effectuez la requête POST avec fetch
    fetch(endpoint, {
        method: "POST",
        headers: {
          contentType: "multipart/form-data"
        },
        body: formData // Utilisez FormData comme corps de la requête
    })
    .then(response => response.json())
    .then(data => {
        setLoading(false);
        console.log("Data:", data);

        if (data.success) {
            toast.success('Data updated successfully');
            navigate(-1);
        }else {
            toast.error('Failed to update data');
        }
    })
    .catch(error => {
        setLoading(false);
        toast.error('Failed to update data');
        console.error("Error:", error);
    });
};


    return (
        <Box>
            <Header />
            <Formik
              enableReinitialize

                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({ 
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                        <Input
                            table={table}
                            config={config}
                            values={values}
                            initialValues={originalValues}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}

                            touched={touched}
                        />
                        </Box>
                             <Box display="flex" justifyContent="end" mt="20px">
              <Button 
              disabled={loading}
              type="submit" color="secondary" variant="contained">
                Submit
              </Button>
              <Button
              disabled={loading}

              onClick={() => {

                const authToken = sessionStorage.getItem("authToken");
                setLoading(true);
                fetch(config.endpoints.delete, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ table, id, authToken }),
                })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    if (data.success) {
                        toast.success('Data deleted successfully');
                        navigate(-1);


                    }else {
                        toast.error('Failed to delete data');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error('Failed to delete data');
                    console.error("Error:", error);
                });

                }
            }

              >
                Delete
                </Button>
            </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Edit;
